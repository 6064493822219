/*! StmwBackend custom.js
 * ================
 * Main JS application file for StmwBackend.
 * This file should be included in all pages.
 *
 * @Author  Daniel Theiss
 */

/*
 * AJAX Setup for CSRF Token
 */
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});